import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React from "react"
import Layout from "../components/layout/layout"

export default function OverOns({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Over ons" location={location}>
      <div className="page">
        <Heading>
          Zoek jij zingevend vrijwilligerswerk waarin je een bijdrage levert aan
          minder verspilling, locale consumptie en eerlijke productie?
        </Heading>
        <br />
        <div>
          <p>
            Daarbij heel veel leert over online marketing, social media e.d?
            Zijn dit de taken die je aanspreken, waar je feeling mee hebt?
            <br />
            <br />
            <ul>
              <li>Beheer social media </li>
              <li>Beheren van informatie, zoals aanmeldingen en reviews </li>
              <li>Toevoegen van blogs over seizoensproducten enzovoorts</li>
              <li>Nieuwe adres toevoegen </li>
            </ul>
            <div>
              <br />
              Stuur ons dan een berichtje (hi@fairsy.nl) om (voor een bepaalde
              regio) helper te worden.
            </div>
          </p>
        </div>
      </div>
    </Layout>
  )
}
